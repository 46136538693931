import React, { Component } from 'react';
import NavBar from './components/NavBar';
import Login from './components/Login';
import EquipmentList from './components/EquipmentList';
import ReportList from './components/ReportList';
import Report from './components/Report';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import api from './api';

import Spinner from 'react-bootstrap/Spinner';
import './App.css';

class App extends Component {
  constructor(props) {
      super(props)

      this.state = { isLoaded: false, isLoggedIn: false, isLoginError: false }
  }

  componentDidMount = async () => {
    await api.getSession().then(res => {
        this.setState({
            isLoaded: true,
            isLoggedIn: res.data.loggedIn
        })
    })
  }

  doLogin = async (uname, passwd) => {
    let payload = {
        username: uname,
        password: passwd
    }

    await api.login(payload).then(res => {
      this.setState({ isLoggedIn: res.data.success, isLoginError: false });
    }).catch(error => {
      this.setState({ isLoginError: true });
    })
  }

  doLogout = async () => {
    await api.logout().then(res => {
        if (res.data.loggedOut) {
            this.setState({ isLoggedIn: false })
        }
    })
  }

  render() {
    if (!this.state.isLoaded)
      return(
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );

    return (
      <div className="App">
        <Router>
          <NavBar isLoggedIn={this.state.isLoggedIn} doLogout={() => this.doLogout()} />
          <section className="App-section">
            {this.state.isLoggedIn ?
              <Switch>
                <Route exact path="/">
                  <h1>Equipments</h1>
                  <EquipmentList />
                </Route>
                <Route path="/equipment/:id" component={(props) => <ReportList equipmentId={parseInt(props.match.params.id)} />} />
                <Route path="/report/:equipment-:id" component={(props) => <Report equipmentId={parseInt(props.match.params.equipment)} reportId={parseInt(props.match.params.id)} />} />
              </Switch>
            : <Login onLogin={this.doLogin} isLoginError={this.state.isLoginError} />}
          </section>
        </Router>
      </div>
    );
  }
}

export default App;
