import axios from 'axios'

const api = axios.create({
    baseURL: 'https://cirs-backend.eris.tech/',
    withCredentials: true
})

export const getEquipments = () => api.get(`/api/equipments`)
export const getEquipmentInfo = id => api.get(`/api/equipments/${id}`)
export const getReport = (eqId, repId) => api.get(`/api/equipments/${eqId}/report/${repId}`)

export const login = payload => api.post(`/login`, payload)
export const getSession = () => api.get(`/session`)
export const logout = () => api.get(`/logout`)

const apis = {
    getEquipments,
    getEquipmentInfo,
    getReport,
    login,
    getSession,
    logout,
}

export default apis
