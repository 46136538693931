import React from 'react';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import logo from '../logo.png';

function NavBar(props){
    return(
        <header className="App-header">
          <div className="App-logo"><img src={logo} alt="ERIS Technologies" /></div>
          <div className="App-header-title"><Link to="/">Cloud Inspection Reporting System&trade;</Link></div>
          <div className="App-header-login">{props.isLoggedIn ? <Button onClick={() => props.doLogout()} variant="secondary">Logout</Button> : null}</div>
        </header>
    );
}

export default NavBar;