import React, { Component } from 'react';
import api from '../api'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Button from 'react-bootstrap/Button';

// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

import 'react-accessible-accordion/dist/fancy-example.css';

class EquipmentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
        list: null
    }
  }
  componentDidMount = async () => {
    await api.getEquipments().then(res => {
        this.setState({
          list: res.data.data
        })
    })
  }

  render() {
    return (
      this.state.list === null ? null
      : <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {this.state.list.map((e, index) =>
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {e.name}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>{e.text}</p><Link to={"/equipment/"+e.id}><Button>See reports</Button></Link>
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
    );
  }
}

export default EquipmentList;
