import React, { Component } from 'react';
import api from '../api';

// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      report: null
    }
  }

  componentDidMount = async () => {
    await api.getReport(this.props.equipmentId, this.props.reportId).then(res => {
        this.setState({
          equipmentName: res.data.equipmentName,
          report: res.data.data
        })
    })
  }

  render() {
    return (
      this.state.report === null ? null :
      <div>
        <h1>{this.state.equipmentName}</h1>
        <h2>Report: {this.state.report.date}</h2>
        <h3>Details</h3>
        <div className="mb-5"><strong>Inspector</strong>: {this.state.report.inspector}</div>
        <h3>Indications</h3>
        <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
          {this.state.report.indications.map((e, index) =>
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {e.pos} m - {e.type}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>

                <div>
                  <img src={`/demo-images/${this.props.reportId}-${e.id}-ir.png`} className="report-ir" alt="IR" />
                  <img src={`/demo-images/${this.props.reportId}-${e.id}-visible.jpg`} className="report-visible" alt="Visible" />
                </div>
                <p>Notes: {e.note}<br /><Link to={"/equipment/"+this.props.equipmentId}></Link></p>
              </AccordionItemPanel>
            </AccordionItem>
          )}
        </Accordion>
        <p className="mt-5"><Link to={`/equipment/${this.props.equipmentId}`}>Return to report list</Link></p>
      </div>
    );
  }
}

export default Report;
