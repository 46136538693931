import React, {useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function Login(props){
  const [showError, setShowError] = useState(true);

  return(
    <Container>
      <Row className="justify-content-md-center">
        <Col md="10" lg="6">
          <Alert show={props.isLoginError && showError} variant="danger" onClose={() => setShowError(false)} dismissible>
            <Alert.Heading>Login error</Alert.Heading>
            <p>Wrong user name or password.</p>
          </Alert>
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm="8" md="6" lg="4" xl="3">
          <Form>
            <Form.Group>
              <Form.Control type="text" id="loginUserName" placeholder="Username" /><br />
              <Form.Control type="password" id="loginPassword" placeholder="Password" /><br />
            </Form.Group>
            <Button as="input" type="submit" onClick={(e) => {e.preventDefault(); props.onLogin(document.getElementById('loginUserName').value, document.getElementById('loginPassword').value); setShowError(true);}} value="Login" />
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;