import React, { Component } from 'react';
import api from '../api';

// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Button from 'react-bootstrap/Button';

class ReportList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      equipment: null
    }
  }
  
  componentDidMount = async () => {
    await api.getEquipmentInfo(this.props.equipmentId).then(res => {
        this.setState({
          equipment: res.data.data
        })
    })
  }

  render() {
    return (
      this.state.equipment === null ? null
       : <div>
          <h1>Reports: {this.state.equipment.name}</h1>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {this.state.equipment.reports.map((e, index) =>
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {e.date}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Indications: {e.indications.length}</p><Link to={"/report/"+this.props.equipmentId+"-"+e.id}><Button>See report</Button></Link>
                </AccordionItemPanel>
              </AccordionItem>
            )}
          </Accordion>
          <p className="mt-5"><Link to={`/`}>Return to equipment list</Link></p>
        </div>
    );
  }
}

export default ReportList;
